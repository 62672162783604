<template>
    <v-container>
        <HeadbarPage :title="'Títulos > Anuência em Massa'"></HeadbarPage>
        <UploadEmMassa
            @buscarTitulos="buscarTitulos"
            :title="'Anuência em Massa'"
            :textAreaLabel="
                'Cole aqui os títulos que deseja solicitar a Anuência'
            "
            :textAreaHint="
                'Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5'
            "
            :instrucaoUpload="
                'Utilize o campo abaixo para fazer upload de arquivo com os títulos em massa para anuência.'
            "
            :textAreaPlaceholder="
                'Cole aqui os títulos que deseja solicitar anuência em massa.'
            "
            :dica="
                'Dica: Anuência pode ser solicitada somente para os títulos com os seguintes status: 102 Protestado, 109 Protestado por Edital, 123 Protestado por Edital(RJ/BA), 221 Enviado a Protesto (será solicitado uma Pré-anuência), 225 Apontado (será solicitado uma Pré-anuência).'
            "
        />
        <TableWithSelections
            ref="tableWithSelections"
            :titulos="titulos"
            :totalTitulos="totalTitulos"
            :loading="loading"
            @selecionados="setSelecionados"
            @changeOptionsPage="changeOptionsPage"
            @selecionarTodos="setTodosSelecionados"
        />
        <CardConfirmacaoEnvioEmMassa
            :selecionados="processarSelecionados"
            :avisoRetorno="avisoRetorno"
            :loadingFiltros="loadingFiltros"
            :resultadoCheck="resultadoCheck"
            :titulosEnviadosCheck="titulosEnviadosCheck"
            :type="'Anuência'"
            @enviar="anuenciaMassa"
        />
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
    </v-container>
</template>
<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';
export default {
    name: 'AnuenciaEmMassaV2',
    components: {
        HeadbarPage: () => import('@/components/atoms/HeadbarPage'),
        UploadEmMassa: () => import('@/components/organisms/UploadEmMassa'),
        TableWithSelections: () =>
            import('@/components/atoms/TableWithSelections'),
        CardConfirmacaoEnvioEmMassa: () =>
            import('@/components/organisms/CardConfirmacaoEnvioEmMassa')
    },
    data() {
        return {
            snackbar: false,
            colorSnackbar: '',
            mensagemSnackbar: '',
            ultimoFiltro: {},
            filtros: {
                cd_status: ''
            },
            tituloService: new TituloService(Vue.http, this.$store),
            options: {
                page: 1,
                itemsPerPage: 300
            },
            titulos: [],
            todosTitulos: [],
            totalTitulos: 0,
            loading: false,
            selecionados: [],
            selecionarTodos: false,
            avisoRetorno: [],
            loadingFiltros: false,
            resultadoCheck: false,
            titulosEnviadosCheck: true,
            initialLoad: true
        };
    },
    computed: {
        processarSelecionados() {
            if (this.selecionarTodos) {
                return this.todosTitulos;
            } else {
                return this.selecionados;
            }
        }
    },
    methods: {
        setSelecionados(selecionados) {
            this.selecionados = selecionados;
        },

        setTodosSelecionados(selecionarTodos) {
            this.selecionarTodos = selecionarTodos;
            if (selecionarTodos) {
                this.buscarTitulos(this.ultimoFiltro);
            }
        },

        formatarDocumento() {
            var nu_identificacao_devedor = this.ultimoFiltro
                .nu_identificacao_devedor;
            if (nu_identificacao_devedor != null) {
                nu_identificacao_devedor = nu_identificacao_devedor.match(
                    /\d/g
                );
                nu_identificacao_devedor = nu_identificacao_devedor.join('');
                return nu_identificacao_devedor;
            } else {
                return null;
            }
        },
        anuenciaMassa(data) {
            if (this.loadingFiltros) {
                return;
            }
            this.loadingFiltros = true;
            this.tituloService
                .anuenciaTitulo(data)
                .then(response => {
                    this.titulosEnviadosCheck = false;
                    this.resultadoCheck = true;
                    this.titulosEnviadosCheck = false;
                    this.loadingFiltros = false;
                    this.avisoRetorno = response.body;
                })
                .catch(() => {
                    this.snackbar = true;
                    this.colorSnackbar = 'error';
                    this.mensagemSnackbar =
                        'Erro ao enviar títulos para cancelamento';
                });
        },

        buscarTitulos(filtros, resetOptions = false) {
            this.loading = true;

            if (resetOptions) {
                this.options.page = 1;
                this.options.itemsPerPage = 300;

                this.$refs['tableWithSelections'].resetSelecionados();
                this.selecionados = [];
            }

            let page = this.options.page;
            let itemsPerPage = this.options.itemsPerPage;
            this.situacoesTitulo = this.$store.getters.situacoesTitulo;
            this.situacoesTitulo.push({
                cd_status: 99999,
                nm_status: 'EM REVISÃO'
            });

            if (
                this.ultimoFiltro == null ||
                JSON.stringify(this.ultimoFiltro) != JSON.stringify(filtros)
            ) {
                this.ultimoFiltro = filtros;
            }

            this.ultimoFiltro.cd_status = '102,109,123,221,225';
            this.ultimoFiltro.nu_identificacao_devedor = this.formatarDocumento();

            let use_filtros = JSON.parse(JSON.stringify(this.ultimoFiltro));

            if (this.selecionarTodos) {
                itemsPerPage = this.totalTitulos;
                use_filtros['linhas_full'] = 1;
                this.todosTitulos = [];
            }

            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;

                try {
                    let service = new TituloService(Vue.http, this.$store);

                    service
                        .filtrarTitulos(use_filtros, page, itemsPerPage)
                        .then(
                            response => {
                                response.status;
                                response.statusText;
                                response.headers.get('Expires');
                                this.exportActive = false;
                                this.loadingFiltros = false;
                                this.loading = false;

                                const {
                                    titulos,
                                    numero_linhas: total
                                } = response.body;

                                if (this.selecionarTodos) {
                                    this.todosTitulos = titulos;
                                } else {
                                    this.titulos = titulos;
                                }

                                this.totalTitulos = total;
                                resolve();
                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                this.mensagem = error;
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },

        changeOptionsPage(options) {
            if (!this.initialLoad) {
                this.options = options;
                this.buscarTitulos(this.filtros);
            } else {
                this.initialLoad = false;
            }
        },
    }
};
</script>
